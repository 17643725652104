var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

var site = site || {};

site.template = site.template || {};

(function ($) {
  Drupal.behaviors.quickshopOverlay = {
    /*
     * jQuery object that stores all the grids after they are set up in initializeGrid
     */
    $grids: $(),

    /*
     * Sets up the product grid for inline quickshop by inserting many js-quickshop-container div elements
     * based upon the data-grid-items-per-row attribute in the product grid (and screen size).
     * Each .js-quickshop-container div also gets specific productID classes so the proper product info
     * goes to the proper container on launch.
     * @param $grids - jQuery object of product grids on the page.
     * @param size - string such as "small" or "medium" for determining the number of products in a row.
     */
    initializeGrid: function ($grids, size) {
      var self = this;

      if (typeof size === 'undefined') {
        size = Unison.fetch.now().name;
      }
      var isMobile = false;

      // Prep each grid (normally just one grid) by adding quickshop container div elements.
      $grids.has('.js-product-grid-item').each(function () {
        // Cache $(this) grid and remove any preexisting quickshop containers.
        var $thisGrid = $(this);

        self.reset($thisGrid);

        var i = 0;
        var classes = '';
        var rows = [];
        var $row = $();
        var $containerDiv = $('<div class="js-quickshop-container product-quickshop-wrapper" />');
        var $items = $('.js-product-grid-item', $thisGrid);
        var totalItems = $items.length;
        var isCarousel = $(this).hasClass('product-grid--carousel');

        // Loop through each item in the product grid.
        $items.each(function (index) {
          var $thisItem = $(this);
          var productID = $thisItem.data('product-id');

          // If there is a product ID available, keep track of it to use in the current quickshop container.
          if (productID) {
            classes += ' js-quickshop-container--' + productID;
          }

          // For carousels, remove the qs link then we just need all the classes
          if (isCarousel) {
            if (Drupal.settings.common.quickshop_in_carousels !== true) {
              $('.product-brief__quickshop-launch', $thisGrid).hide();
            }

            return;
          }

          // If mobile, then every product in the grid gets its own quickshop container immediately after it.
          if (isMobile) {
            $thisItem.after($containerDiv.clone()).next().addClass(classes);
            classes = '';
            rows.push($thisItem);

            // Else if desktop, then products share a quickshop container according to the number per row in the grid.
          } else {
            var span = 1;
            var itemsPerRow = $thisGrid.data('grid-items-per-row')[size] || 3;

            i += span;
            $row = $row.add($thisItem);

            // The count has either reached the end of the row, or the end of the entire grid. Append the quickshop container!
            if (i === itemsPerRow || index + 1 === totalItems) {
              $thisItem.after($containerDiv.clone()).next().addClass(classes);
              rows.push($row);
              i = 0; // Reset the count.
              $row = $(); // Empty the jQuery object holding the current row of items.
              classes = ''; // Clear the class name list.
            }
          }
          $thisItem.on('focusin', function () {
            var $current_product = $(this);
            var $product = $current_product.find('.js-product');
            var tabbable_last = $current_product.find(':tabbable').filter(':last');

            $product.addClass('focused');
            tabbable_last.on('focusout', function () {
              if ($product.hasClass('focused')) {
                $product.removeClass('focused');
              }
            });
            $current_product.off('keydown').on('keydown', function (e) {
              if (e.shiftKey && $(this).hasClass('custom-outline')) {
                $product.removeClass('focused');
                $product.prev().addClass('focused');
              }
            });
          });
        });

        if (isCarousel) {
          // slick wraps our $items in its own stuff so we have to treat those
          // as items if they've been set.
          var $slides = $('.slick-slide', this);

          rows = $slides.length ? [$slides] : [$items];
          $(this).append($containerDiv.clone().addClass(classes));
        }

        // Storage of completed grid and its rows.
        $thisGrid.data('product-grid-rows', rows);
        self.$grids.add($thisGrid);
      });
    },

    /* Clear the product grid of all quickshop containers.
     * @params $grid - jQuery object of product grid to reset
     */
    reset: function ($grid) {
      // Remove all quickshop div container elements.
      $('.js-quickshop-container', $grid).remove();

      // Remove active class from all product briefs.
      $('.js-product-brief', $grid).removeClass('active');
    },

    /*
     * Standard Drupal method
     */
    attach: function (context) {
      var self = this;
      var $grid = $('.js-product-grid', context);
      // Get screen size.
      var size = Unison.fetch.now().name;

      // If no grid, do nothing
      if (!$grid.length) {
        return;
      }
      self.initializeGrid($grid, size);
      // Setup an event handler to init this grid
      $grid.on('product.initializeGrid', function (event) {
        self.initializeGrid($(this), Unison.fetch.now().name);
      });
      $grid.on('product.resetGrid', function (event) {
        self.reset($(this));
      });
      $(document).trigger('product.quickshopInit', [
        {
          type: {
            overlay: true,
            toString: function () {
              return 'overlay';
            }
          },
          init: this.initializeGrid,
          reset: this.reset,
          $grids: this.$grids
        }
      ]);
    }
  };

  /*
   * 4 custom events below:
   * product.quickshop.launch.inline
   * product.quickshop.launch.overlay
   * product.quickshop.close.inline
   * product.quickshop.close.overlay
   */

  // Uses the supplied .js-product to find the correct .js-quickshop-container to use.
  // Then loads it with the appropriate product data for the product_quickshop.mustache template.
  // Finally, shows the quickshop content inline.
  $(document).on('product.quickshop.launch.inline', '.js-product', function (e) {
    // Store relevant product values.
    var $product = $(this);
    var productID = $product.data('product-id');
    var productData = prodcat.data.getProduct(productID);

    $.extend(productData, Drupal.settings.common);

    // Store other elements for inserting product info into quickshop container.
    var $grid = $product.closest('.js-product-grid');
    var $gridItem = $product.closest('.js-product-grid-item');
    var $allgridItems = $('.js-product-grid-item', $grid);
    var $allProducts = $('.js-product-brief', $grid);
    var $containers = $('.js-quickshop-container', $grid);
    var $container = $containers.filter('.js-quickshop-container--' + productID); // Get specific container to use for this product.
    var $activeContainers = $containers.filter('.active');
    var isCarousel = $grid.hasClass('product-grid--carousel');
    // Initialize some other variables
    var alreadyActive = $product.hasClass('active');
    var template = 'product_quickshop';
    var animationSpeed = 400;
    var quickshopTop = 0;
    var quickshopOffset = -200;
    var navHeight = $('.site-header').height();

    // Close the quickshop inline if it is already active (and thefore already open).
    if (alreadyActive) {
      $product.trigger('product.quickshop.close.inline');

      return;
    }

    // @TODO Check to make sure this is even needed at this point:
    // If the product has data-available-skus set, then only
    // display the skus available in the attribute.
    // See Origins for actual code...

    // Render the quickshop template.
    var rendered = site.template.get({
      name: template,
      data: productData
    });

    // Make some items disappear before the big reveal.
    $activeContainers.hide();
    $('.js-quickshop', $container).remove();
    $container.append(rendered).slideDown();
    $(".product-quickshop.js-product.js-quickshop[data-product-id*='" + productData.PRODUCT_ID + "']").addClass(
      'product-quickshop-inline'
    );

    // Set up an event trigger for the inline close button.
    $(document).on('click', '.js-quickshop-close-inline', function (e) {
      e.preventDefault();
      $product.trigger('product.quickshop.close.inline');
    });

    $grid.trigger('product.quickshop.animation.before');

    // Animate the window to bring the quickshop container into view.
    quickshopTop = $container.offset().top;

    $('html, body').animate(
      {
        scrollTop: quickshopTop + quickshopOffset
      },
      {
        duration: animationSpeed
      }
    );

    $allgridItems.removeClass('item-active');
    $allProducts.removeClass('active');
    $gridItem.addClass('item-active');
    $product.addClass('active');
  });

  // Uses the supplied .js-product to load the proper product data.
  // Then fires up an overlay with the product_quickshop.mustache template.
  $(document).on('product.quickshop.launch.overlay', '.js-product', function () {
    // Store relevant product values
    var productID = $(this).data('product-id');
    var productData = prodcat.data.getProduct(productID);
    var template = 'product_quickshop';
    var screenOrientation = Unison.fetch.now().name;
    var overlayWidth = 'auto';

    if (screenOrientation === 'landscape') {
      overlayWidth = '900px';
    }

    // Render the quickshop template
    var rendered = site.template.get({
      name: template,
      data: productData
    });

    generic.overlay.launch({
      content: rendered,
      height: 'auto',
      width: overlayWidth,
      cssClass: 'qs-overlay-cbox',
      onComplete: function () {
        $.colorbox.resize();
      }
    });
  });

  // Closes the inline quickshop template according to the .js-product supplied.
  $(document).on('product.quickshop.close.inline', '.js-product', function () {
    var $product = $(this);
    var $productGridItem = $product.parent('.product-grid__item');
    var productID = $product.data('product-id');
    var $grid = $product.closest('.js-product-grid');
    var $container = $('.js-quickshop-container--' + productID, $grid);
    var animationSpeed = 400;

    $product.removeClass('active');
    $productGridItem.removeClass('item-active');

    $container.slideUp(animationSpeed, function () {
      $grid.trigger('product.quickshop.animation.after');
    });
  });

  // In response to clicking the inline launch link, find the closest product
  // and then trigger the appropriate event for the code above.
  $(document).on('click', '.js-quickshop-launch-inline', function (e) {
    e.preventDefault();
    $(this).closest('.js-product').trigger('product.quickshop.launch.inline');

    // Trigger 'product.init' to set product status
    $('.js-product', '.js-quickshop').trigger('product.init');
  });

  // Same as above, except for the overlay launch link.
  $(document).on('click', '.js-quickshop-launch-overlay', function (e) {
    e.preventDefault();
    $(this).closest('.js-product').trigger('product.quickshop.launch.overlay');

    // Trigger 'product.init' to set product status
    $('.js-product', '.js-quickshop').trigger('product.init');
  });
})(jQuery);
